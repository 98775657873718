.slick-dots li {
    width: 10px;
    margin: 0 5px;
    height: 10px;
    background-color: grey;
    border-radius:100%;
  }

  .slick-dots .slick-active {
    width: 10px;
    margin: 0 5px;
    height: 10px;
    background-color: white;

  }

  .slick-dots li.slick-active button:before{
    color: white;
    display: none;
    
  }

  .slick-dots li button:before{
    color: transparent;
  } 